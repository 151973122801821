import NextHead from 'next/head';
import useTranslation from 'next-translate/useTranslation';
import { FC } from 'react';

const Head: FC = () => {
    const { t } = useTranslation('common');

    return (
        <NextHead>
            <title>{t('appTitle')}</title>
            <meta content="width=device-width, initial-scale=1" name="viewport" />
            <link href="/favicon.svg" rel="icon" sizes="32x32" type="image/png" />
        </NextHead>
    );
};

export default Head;
